export const title = "Pixelfonts like it’s 1982";
export const subTitle = "2024 · Tag der Schrift · Stefan Huber";
export const defaultFontName = "Tag der Schrift 2024";

// export const $t = {
//   showPreview: "preview",
//   showEdit: "edit",
//   exportFontOpenType: "Export Font (OpenType)",
//   exportFontName: "Font Name",
//   saveJSON: "Save (JSON)",
//   importJSON: "Import (JSON)",
//   allCharacters: "↑ insert all characters ↑",
// };


export const $t = {
  showPreview: "Vorschau",
  showEdit: "Bearbeitung",
  exportFontOpenType: "Schrift exportieren (OpenType)",
  exportFontName: "Schrifname",
  saveJSON: "speichern (JSON)",
  importJSON: "importieren (JSON)",
  allCharacters: "↑ alle Zeichen einfügen ↑",
  delete: "Font löschen",
  confirmDelete: "Bestätigen Sie das Löschen",
};